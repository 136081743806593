.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row-centered {
  display: flex;
  align-self: center;
}

.upload-image {
  width: 100%;
}

.upload-image-select {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.upload-image-select p {
  justify-self: end;
}

.table-4-col {
  display: grid;
  grid-template-rows: auto 1fr 1fr 1fr 1fr auto;
}

.table-observingtimes {
  display: grid;
  grid-template-columns: 0.5fr 3fr 6fr 2fr 2fr 2fr 1fr;
  column-gap: 5px;
}

.table-awardedtimes {
  display: grid;
  grid-template-columns: 0.5fr 10fr 4fr 2fr 2fr 1fr;
  column-gap: 5px;
}

.table-publications {
  display: grid;
  grid-template-columns: 0.5fr 18fr 1fr;
  column-gap: 5px;
}

.table-targets {
  display: grid;
  vertical-align: text-bottom;
  grid-template-columns: 0.5fr 5fr 3fr 3fr 3fr 2fr 2fr 2fr 1fr;
  column-gap: 5px;
}

.table-header {
  align-items: flex-end;
}

.row-button {
  height: fit-content;
}

.error-message {
  color: red;
  font-size: 0.75em;
  display: relative;
}

.submit-message {
  color: red;
  font-size: 1.5em;
  display: relative;
}

.navbar-container {
  width:100%;
  display: flex;
  justify-content: space-between;
}

.navbar-text {
  color: #BACCDF;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.navbar-text p {
  padding-right: 10%;
  line-height: 1.1em;
  font-size: 1.2rem;
  font-weight: 200;
}

.navbar-text-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.navbar-content {
  display: block;
}

.navbar-buttons {
align-content: right;
}

.navbar-double-button {
  display: flex;
  justify-content: flex-end;
  min-width: 150px;
  align-items: center;
  column-gap: 10px;
}

.teal {
  color: teal;
}

.login-alert {
  text-align: right;
}

.cards {
  margin: 50px;
  display: grid;
  column-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
}

.card-selector {
  padding: 40px;
  min-height: 200px;
  box-shadow: 4px 4px 15px lightgrey;
  text-align: center;
  text-decoration: none;
  color: black;
  transition: all .2s ease-in-out;
}

.card-selector:hover {
  transform: scale(1.1);
}

.card-link:hover {
  text-decoration: none;
}

.card-selector h1 {
  color: teal;
  margin-bottom: 20px;
}

.required-mark {
  vertical-align: super;
  color: indianred;
  margin-left: 0.2em;
  font-size: xx-small;
  
}

.info-text {
  padding: 10px;
  border: solid 1px lightsteelblue;
  border-radius: 5px;
  background-color:aliceblue;
  color: steelblue;
  font-size: small;
}

.info-text svg {
  margin-right: 0.75rem;
}

.align-left {
  margin: 0px 10px 10px auto;
}